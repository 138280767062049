import PropTypes from 'prop-types';
import classNames from 'classnames';

import {StorySubheadline} from '@gisatcz/visat-components';

import './style.scss';
import DownloadData from './DownloadData';
import OrderServiceTool from '../OrderServiceTool';
import Link from '../Link';
import {getRouter} from '../../../router';

var StoryFooterSection = ({
	applicationStoryKey,
	otherApps,
	downloadOptions,
	user,
}) => {
	const userIsLogged = user && user?.data?.name !== 'guest';
	return (
		<div className="cure-Story-mainPanel-footer">
			<div className="cure-Story-mainPanel-footerSection">
				<StorySubheadline className={''} isFooterTitle>
					Download Data
				</StorySubheadline>
				<p>
					Download data package for individual cities. The products are maps in
					geotiff image format of Land Surface Temperature (Kelvin) of 100 m x
					100 m spatial resolution for a specific time of day.
				</p>
				<DownloadData downloadOptions={downloadOptions} />
			</div>
			<div className="cure-Story-mainPanel-footerSection">
				<StorySubheadline className={''} isFooterTitle>
					Order service
				</StorySubheadline>
				{!userIsLogged ? (
					<p>You have to be logged in...</p>
				) : (
					<OrderServiceTool applicationStoryKey={applicationStoryKey} />
				)}
			</div>
			<div
				className={classNames(
					'cure-Story-mainPanel-footerSection',
					{},
					'is-last'
				)}
			>
				<StorySubheadline className={''} isFooterTitle>
					Continue to other applications
				</StorySubheadline>
				{otherApps.map(options => {
					return (
						<Link
							key={options?.key}
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: options?.key}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							{options?.label}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

StoryFooterSection.propTypes = {
	applicationStoryKey: PropTypes.string,
	otherApps: PropTypes.array,
	downloadOptions: PropTypes.array,
	user: PropTypes.object,
};

export default StoryFooterSection;
